<template>
  <v-container>
    <v-row class="my-12">
      <v-col
        cols="12"
        md="6"
        sm="6"
        lg="6"
        v-for="{ description, id, title, list } in misionVision"
        :key="id"
      >
        <v-card flat style="border-radius: 10px" height="100%">
          <v-card-title>
            <h1 class="text-h5 font-weight-bold">{{ title }}</h1>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="font-weight-light">
            <p>{{ description }}</p>
            <ul>
              <li v-for="(e, index) in list" :key="index">{{ e }}</li>
            </ul>
            <!-- list -->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

   
  </v-container>
</template>

<script setup lang="ts">


const misionVision = ref<
  { id: string; title: string; description: string; list: Array }[]
>([
  {
    id: "mision",
    title: "Misión",
    description:
      "Somos la Empresa Nacional de Energía Eléctrica que opera para mejorar la calidad de los servicios de electricidad y llevar dicho servicio a todos los rincones del país, siendo una plataforma que contribuya a reducir la pobreza energética y a impulsar el desarrollo de la economía social de la nación.",
    list: [],
  },
  {
    id: "vision",
    title: "Visión",
    description:
      "Ser para el 2026 una de las empresas públicas eléctricas más importante de la región, preservando la categoría de patrimonio nacional y considerando al servicio de energía eléctrica como un recurso estratégico para la nación y un derecho humano de naturaleza económica social, además, asegurándole a los ciudadanos tarifas justas y accesibles para las amplias capas poblacionales del país.",
    list: [],
  },
  {
    id: "valores",
    title: "Valores",
    description:
      "La Empresa Nacional de Energía Eléctrica orienta sus acciones hacia el cumplimiento de las funciones que la ley le asigna, tomando como guía o fundamentos básicos los siguientes valores: ",
    list: [' Responsabilidad ',' Honradez',' Disciplina',' Eficiencia Ética',' Transparencia ',' Calidad','Efectividad',' Integridad',' Trabajo en Equipo ',' Servicio',' Honestidad',' Compromiso',' Respeto']
  },
  {
    id: "oje",
    title: "Objetivos Estratégicos",
    description: "",
    list: [
      "Fortalecer las unidades administrativas a través de un clima laboral propicio que impulse el desarollo humano.",
      "Impulsar la separación técnica, administrativa y financieras de las unidades de negocio.",
      "Recuperar financieramente la ENEE.",
      "Reducir las pérdidas de energía eléctrica.",
      "Garantizar el servicio de la energía eléctrica.",
    ],
  },
]);
</script>

<style scoped lang="scss">


ul {
  margin-left: 20px;
  margin-top: 10px;
  list-style: square url("/arrowcopy.svg");
  //  list-style-image: url('/arrowxopy.svg')
}
</style>
